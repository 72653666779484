
import { defineComponent } from "vue";
import axios from "axios";
import { Modal } from "bootstrap";
import CollectionCategory from "@/components/MyFavoritePost/CollectionCategory.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    CollectionCategory,
  },
  props: ["info", "modalId"],
  emits: [
    "moveToCollectDone",
    "addToFollow",
    "newCollection",
    "newCollectionError",
    "noSelectCollectionCategoryId",
  ],
  data() {
    return {
      spinnerStatus: true,
      show: "new-collection", // new-collection , enter-collection-title
      list: [],
      collectionCategoryName: "",
      collectionCategoryId: 0,
      // 被選中的分類名稱
      collectionCategoryTitle: "",
      nameLimit: false,
    };
  },
  methods: {
    favoriteListGet() {
      this.spinnerStatus = true;
      axios
        .get(`${serverUrl}thematic_learning/collect`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          this.list = [];
          this.list = res.data.data;
          this.spinnerStatus = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    followPost() {
      if (this.collectionCategoryId === 0) {
        // alert("請選擇一個分類");
        this.$emit("noSelectCollectionCategoryId");
        return;
      }
      // 從動態牆主頁發送的加入收藏
      if (this.info.from === undefined) {
        axios
          .patch(
            `${serverUrl}thematic_learning/post/${this.info.postId}/collect`,
            {
              collect: this.collectionCategoryId,
              follow: this.info.status,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log('收藏貼文')
            this.$emit("addToFollow", {
              postIndex: this.info.postIndex,
              postId: this.info.postId,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }

      // 從電腦版我的收藏頁面要移動收藏
      else if (this.info.from === "my-favorite-post") {
        this.$emit("moveToCollectDone", {
          title: this.collectionCategoryTitle,
        });
        axios
          .patch(
            `${serverUrl}thematic_learning/post/${this.info.postId}/collect`,
            {
              collect: this.collectionCategoryId,
              follow: true,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log('收藏貼文')
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    newCollectionCategory() {
      // console.log("新建收藏分類");
      if (this.collectionCategoryName.length > 20) {
        return;
      }
      if (this.collectionCategoryName !== "") {
        axios
          .post(
            `${serverUrl}thematic_learning/collect`,
            {
              title: this.collectionCategoryName,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            this.collectionCategoryName = "";
            this.favoriteListGet();
            this.showChange("new-collection");
            this.$emit("newCollection", {
              id: res.data.data.id,
              title: this.collectionCategoryName,
            });
          })
          .catch((error) => {
            console.error(error);
            this.$emit("newCollectionError");
          });
      }
    },
    sendCollectionCategoryId(id: number) {
      this.collectionCategoryId = id;
      // console.log(this.collectionCategoryId);
    },
    optionChecked(obj: { id: number }) {
      // 將除了被選中以外的選項的 checked 都設為 false ，看起來才有互鎖的效果
      for (let i = 0; i < this.list.length; i++) {
        let target = document.getElementById(
          `${this.modalId}-collection-category-input-${
            (this as any).list[i].id
          }`
        ) as HTMLInputElement;
        if ((this as any).list[i].id !== obj.id) {
          target.checked = false;
        }
      }
    },
    showChange(page: string) {
      this.show = page;
    },
    sendCollectionCategoryTitle(title: string) {
      this.collectionCategoryTitle = title;
    },
  },
  watch: {
    collectionCategoryName: function () {
      if (this.collectionCategoryName.length > 20) {
        this.nameLimit = true;
      } else {
        this.nameLimit = false;
      }
    },
  },
  mounted() {
    // console.log(this.list);
    this.favoriteListGet();
  },
});
