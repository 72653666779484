
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: ["info"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showToast() {
      // console.log("toast顯示")
      document.getElementById("toast-container")!.style.visibility = "visible";
      document.getElementById("toast-container")!.style.opacity = "1";
      document.getElementById("toast-text")!.textContent = this.info;
      // 這邊setTimeout會有在背景疊加的問題，假設快速點收藏按鈕，會累積一堆關閉toast的執行，會導致
      // 視覺上有時候看到toast會很快消失，之後再進行修改
      if (!this.show) {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    },
    hideToast() {
      // console.log("hide")
      document.getElementById("toast-container")!.style.visibility = "hidden";
      document.getElementById("toast-container")!.style.opacity = "0";
      this.show = false;
    },
  },
});
