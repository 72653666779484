import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47dcfc58"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "toast-container",
  class: "toast-container"
}
const _hoisted_2 = { class: "d-flex flex-row" }
const _hoisted_3 = { id: "toast-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.info), 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.hideToast()), ["stop"])),
        class: "bi bi-x"
      })
    ])
  ]))
}