
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: ["info", "modalId"],
  emits: [
    "sendCollectionCategoryId",
    "optionChecked",
    "sendCollectionCategoryTitle",
  ],
  data() {
    return {
      id: -1,
      title: "",
    };
  },
  methods: {
    optionChecked() {
      let target = document.getElementById(
        `${this.modalId}-collection-category-input-${this.id}`
      ) as HTMLInputElement;
      target.checked = true;
      this.$emit("optionChecked", { id: this.id });
    },
    sendId() {
      this.$emit("sendCollectionCategoryId", this.id);
    },
    sendTitle() {
      this.$emit("sendCollectionCategoryTitle", this.title);
    },
  },
  mounted() {
    // console.log(this.info);
    this.id = this.info.id;
    this.title = this.info.title;
  },
});
